<template>
    <section class="wrapper news">
        <div class="container">
            <div class="line">
                <div class="row space-t">
                    <div class="col-md-12">
                        <h1 class="text-center">{{ $t('news.news2.title') }}</h1>
                    </div>
                </div>
                <div class="row space-t">
                    <div class="col-md-4">
                        <img src="../../assets/share-architects.jpg" class="card-img-top" alt="Share Architects">
                    </div>
                    <div class="col-md-8">
                        <p v-html="$t('news.news2.shortdesc')"></p>
                    </div>
                </div>
            </div>
        </div>
</section>
</template>

<script>




export default {
    name: "ShareArchitects",
    setup() {

    },
}
</script>
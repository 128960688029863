<template>
    <section class="wrapper companies mr-light">
        <div class="container animate__animated animate__fadeIn">
            <div class="row">
                <div class="col-md-12">
                    <div class="logo text-center">
                        <a href="#"><img src="../../assets/comp/mr-light-logo.png" alt="Mr Light"/></a>
                        <p class="slogan">{{ $t('companies.mr-light.slogan') }}</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div v-html="$t('companies.mr-light.desc')" class="col-md-8 offset-md-2 col-sm-12 mt-4 mb-4"></div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "Mrlight",
    setup() {
        
    },
}
</script>
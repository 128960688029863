<template>
    <section class="wrapper companies ssd">
        <div class="container animate__animated animate__fadeIn">
            <div class="row">
                <div class="col-md-12">
                    <div class="logo text-center">
                        <a href="#"><img src="../../assets/comp/ssd-logo.png" alt="SSD"/></a>
                        <p class="slogan" v-html="$t('companies.ssd.slogan')"></p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-8 offset-md-2 col-sm-12 mt-4 mb-4">
                    <p v-html="$t('companies.ssd.desc')"></p>
                </div>
                <div class="col-md-8 offset-md-2 col-lg-4 mb-4">
                    <p>{{ $t('companies.ssd.hdcvi.title') }}</p>
                    <div class="d-flex align-items-center align-items-center mb-1">
                        <div class="icon icon-shape bg-primary rounded-circle mr-4">
                            <i class="fas fa-check"></i>
                        </div>
                        <span>{{ $t('companies.ssd.hdcvi.1') }}</span>
                    </div>
                    <div class="d-flex align-items-center align-items-center mb-1">
                        <div class="icon icon-shape bg-primary rounded-circle mr-4">
                            <i class="fas fa-check"></i>
                        </div>
                        <span>{{ $t('companies.ssd.hdcvi.2') }}</span>
                    </div>
                    <div class="d-flex align-items-center align-items-center mb-1">
                        <div class="icon icon-shape bg-primary rounded-circle mr-4">
                            <i class="fas fa-check"></i>
                        </div>
                        <span>{{ $t('companies.ssd.hdcvi.3') }}</span>
                    </div>
                </div>
                <div class="col-md-8 col-lg-4 mb-4">
                    <p>{{ $t('companies.ssd.ip.title') }}</p>
                    <div class="d-flex align-items-center align-items-center mb-1">
                        <div class="icon icon-shape bg-primary rounded-circle mr-4">
                            <i class="fas fa-check"></i>
                        </div>
                        <span>{{ $t('companies.ssd.hdcvi.1') }}</span>
                    </div>
                    <div class="d-flex align-items-center align-items-center mb-1">
                        <div class="icon icon-shape bg-primary rounded-circle mr-4">
                            <i class="fas fa-check"></i>
                        </div>
                         <span>{{ $t('companies.ssd.hdcvi.2') }}</span>
                    </div>
                    <div class="d-flex align-items-center align-items-center mb-1">
                        <div class="icon icon-shape bg-primary rounded-circle mr-4">
                            <i class="fas fa-check"></i>
                        </div>
                        <span>{{ $t('companies.ssd.hdcvi.3') }}</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-8 offset-md-2 col-lg-4 mb-4">
                    <span class="badge">{{ $t('companies.ssd.others.title') }}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-md-8 offset-md-2 col-lg-4 mb-4">
                    <div class="d-flex align-items-center align-items-center mb-1">
                        <div class="icon icon-shape bg-primary rounded-circle mr-4">
                            <i class="fas fa-check"></i>
                        </div>
                        <span>{{ $t('companies.ssd.others.1') }}</span>
                    </div>
                    <div class="d-flex align-items-center align-items-center mb-1">
                        <div class="icon icon-shape bg-primary rounded-circle mr-4">
                            <i class="fas fa-check"></i>
                        </div>
                        <span>{{ $t('companies.ssd.others.2') }}</span>
                    </div>
                    <div class="d-flex align-items-center align-items-center mb-1">
                        <div class="icon icon-shape bg-primary rounded-circle mr-4">
                            <i class="fas fa-check"></i>
                        </div>
                       <span>{{ $t('companies.ssd.others.3') }}</span>
                    </div>
                    <div class="d-flex align-items-center align-items-center mb-1">
                        <div class="icon icon-shape bg-primary rounded-circle mr-4">
                            <i class="fas fa-check"></i>
                        </div>
                        <span>{{ $t('companies.ssd.others.4') }}</span>
                    </div>
                    <div class="d-flex align-items-center align-items-center mb-1">
                        <div class="icon icon-shape bg-primary rounded-circle mr-4">
                            <i class="fas fa-check"></i>
                        </div>
                        <span>{{ $t('companies.ssd.others.5') }}</span>
                    </div>
                    <div class="d-flex align-items-center align-items-center mb-1">
                        <div class="icon icon-shape bg-primary rounded-circle mr-4">
                            <i class="fas fa-check"></i>
                        </div>
                        <span>{{ $t('companies.ssd.others.6') }}</span>
                    </div>
                    <div class="d-flex align-items-center align-items-center mb-1">
                        <div class="icon icon-shape bg-primary rounded-circle mr-4">
                            <i class="fas fa-check"></i>
                        </div>
                        <span>{{ $t('companies.ssd.others.7') }}</span>
                    </div>
                </div>
                <div class="col-md-8 col-lg-4 mb-4">
                    <div class="d-flex align-items-center align-items-center mb-1">
                        <div class="icon icon-shape bg-primary rounded-circle mr-4">
                            <i class="fas fa-check"></i>
                        </div>
                        <span>{{ $t('companies.ssd.others2.1') }}</span>
                    </div>
                    <div class="d-flex align-items-center align-items-center mb-1">
                        <div class="icon icon-shape bg-primary rounded-circle mr-4">
                            <i class="fas fa-check"></i>
                        </div>
                        <span>{{ $t('companies.ssd.others2.2') }}</span>
                    </div>
                    <div class="d-flex align-items-center align-items-center mb-1">
                        <div class="icon icon-shape bg-primary rounded-circle mr-4">
                            <i class="fas fa-check"></i>
                        </div>
                        <span>{{ $t('companies.ssd.others2.3') }}</span>
                    </div>
                    <div class="d-flex align-items-center align-items-center mb-1">
                        <div class="icon icon-shape bg-primary rounded-circle mr-4">
                            <i class="fas fa-check"></i>
                        </div>
                        <span>{{ $t('companies.ssd.others2.4') }}</span>
                    </div>
                    <div class="d-flex align-items-center align-items-center mb-1">
                        <div class="icon icon-shape bg-primary rounded-circle mr-4">
                            <i class="fas fa-check"></i>
                        </div>
                        <span>{{ $t('companies.ssd.others2.5') }}</span>
                    </div>
                    <div class="d-flex align-items-center align-items-center mb-1">
                        <div class="icon icon-shape bg-primary rounded-circle mr-4">
                            <i class="fas fa-check"></i>
                        </div>
                        <span>{{ $t('companies.ssd.others2.6') }}</span>
                    </div>
                    <div class="d-flex align-items-center align-items-center mb-1">
                        <div class="icon icon-shape bg-primary rounded-circle mr-4">
                            <i class="fas fa-check"></i>
                        </div>
                        <span>{{ $t('companies.ssd.others2.7') }}</span>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-8 offset-md-2">
                    <span class="badge">{{ $t('companies.ssd.brands') }}</span>
                    <div class="markat mt-4">
                        <swiper
                            :slides-per-view="4"
                            :space-between="50"
                            navigation
                            :loop='true'
                            :options="swiperOptions" class="swiper">
                            <swiper-slide><img src="../../assets/markat/dahua.png" alt="Ratech"/></swiper-slide>
                            <swiper-slide><img src="../../assets/markat/teletek.png" alt="Ratech"/></swiper-slide>
                            <swiper-slide><img src="../../assets/markat/zkt.png" alt="Ratech"/></swiper-slide>
                            <swiper-slide><img src="../../assets/markat/roger.png" alt="Ratech"/></swiper-slide>
                            <swiper-slide><img src="../../assets/markat/karel.png" alt="Ratech"/></swiper-slide>
                            <swiper-slide><img src="../../assets/markat/zeybek.png" alt="Ratech"/></swiper-slide>
                            <swiper-slide><img src="../../assets/markat/aypro.png" alt="Ratech"/></swiper-slide>
                            <swiper-slide><img src="../../assets/markat/armagan.png" alt="Ratech"/></swiper-slide>
                            <swiper-slide><img src="../../assets/markat/elektral.png" alt="Ratech"/></swiper-slide>
                            <swiper-slide><img src="../../assets/markat/prosen.png" alt="Ratech"/></swiper-slide>
                        </swiper>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';

import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';

SwiperCore.use([Navigation, Pagination]);


export default {
    name: "SSD",

    components: {
        Swiper,
        SwiperSlide
    },

    data() {
      return {
        swiperOption: {
          direction: 'vertical',
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          }
        }
      }
    }
}

</script>

<style scoped>
    .swiper-button-next:after,
    .swiper-button-prev:after{
        font-size: 20px!important;
    }
</style>
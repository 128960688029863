<template>
  <section class="info-wrapper animate__animated animate__fadeIn">
    <div class="container">
      <div class="row">
        <div class="col-md-8 offset-md-2 line">
          <div class="row">
            <div class="col-md-3 d-flex align-items-center">
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <i class="far fa-star"></i>
                </div>
                <div class="flex-grow-1 ms-3 mt-3">
                    <ul>
                      <li><a href="#">{{ $t('sitemap.pages.new') }}</a></li>
                      <li><a href="#">{{ $t('sitemap.pages.notes') }}</a></li>
                      <li><a href="#">{{ $t('sitemap.pages.jobs') }}</a></li>
                    </ul>
                </div>
              </div>
            </div>
            <div class="col-md-3 d-flex align-items-center">
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <i class="fas fa-home"></i>
                </div>
                <div class="flex-grow-1 ms-3">
                  <p v-html="$t('sitemap.address')"></p>
                </div>
              </div>
            </div>
            <div class="col-md-3 d-flex align-items-center">
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <i class="fas fa-envelope-open-text"></i>
                </div>
                <div class="flex-grow-1 ms-3">
                  <p>
                    {{ $t('sitemap.email') }} <br> info@rm-group.al 
                    </p>
                </div>
              </div>
            </div>
           <div class="col-md-3 d-flex align-items-center">
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <i class="far fa-clock"></i>
                </div>
                <div class="flex-grow-1 ms-3">
                  <p v-html="$t('sitemap.hours')"></p>
                  <p>08:30 – 17:00</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

  export default {
    name: "Sitemap",

  };

</script>
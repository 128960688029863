<template>
    <section class="wrapper rreth-nesh">
        <div class="container">
            <div class="line">
                <div class="row space-t">
                    <div class="col-md-12">
                        <h1 class="text-center">{{ $t('menu.aboutUs') }}</h1>
                    </div>
                </div>
                <div class="row space-t">
                    
                    <div class="col-md-4">
                        <img src="../assets/rm-logo.png" alt="R&M Group Logo" />
                    </div>
                    <div class="col-md-8" v-html="$t('about')"></div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "RrethNesh",
    setup() {
        
    },
}
</script>
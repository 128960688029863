<template>
  <div class="container">
      <div class="row">
          <div class="col-md-12">
              <div class="poopy-browser">
                <div class="close"></div>
                <div class="minimize"></div>
                <div class="maximize"></div>
                <div class="address-bar"></div>
                <div class="x"></div>
            </div>
          </div>
          <div class="col-md-12 text-center mt-4">
              <h2>{{ $t('404.desc') }}</h2>
              <router-link :to="{ path: '/' }">{{ $t('404.button') }}</router-link>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: "NotFound",
}
</script>

<style scoped>

  .poopy-browser {
  width: 45%;
  min-height: 200px;
  height: 40%;
  border: 3px solid #707070;
  border-radius: 10px;
  padding: 10px;
  margin: 0 auto;
  position: relative;
  margin-top: 8%;
  margin-bottom: 4%;
  overflow: hidden;
}

.close, .maximize, .minimize {
  border-radius: 50%;
  border: 3px solid #6f6f6f;
  width: 5px;
  height: 5px;
  float: left;
  margin-right: 3%;
}

.address-bar {
  width: 108%;
  margin-left: -4%;
  border-top: 3px solid #6f6f6f;
  margin-top: 20px;
}

.x {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  border: 3px solid #6f6f6f;
  top: 55%;
  margin-left: -30px;
  left: 50%;
  margin-top: -30px;
  position: absolute;
}
.x:before, .x:after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  width: 40px;
  height: 3px;
  position: absolute;
  top: 50%;
  margin-top: -1.5px;
  left: 50%;
  margin-left: -20px;
  background-color: #6f6f6f;
  content: "";
  display: block;
}
.x:after {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

</style>

<template>
  <section class="wrapper">
    <div class="container">
      <div class="line">
        <div class="row space-t">
          <div class="col-md-12">
            <GMapMap
              :center="center"
              :zoom="18"
              map-type-id="satellite"
              style="height: 500px"
            >
            <GMapMarker
                :key="index"
                v-for="(m, index) in markers"
                :position="m.position"
                :icon="{ url: require('../assets/pin.png')}"
                @click="center=m.position"
            />
          </GMapMap>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: "Kontakt",

  data() {
    return {
      center: {lat: 41.321698, lng: 19.794368},

      markers: [
        {
          position: {
            lat: 41.321698, lng: 19.794368
          },
        }
      ]
    }
  }
};

</script>
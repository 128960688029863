<template>
    <section class="wrapper companies-holder">
        <div class="container">
            <div class="line">
                <div class="row space-t text-center">
                    <div class="col-md-3">
                        <router-link :to="{ path: 'ratech' }">
                            <img src="../assets/comp/ratech-logo.png" alt="Ratech">
                        </router-link>
                    </div>
                    <div class="col-md-3">
                        <router-link :to="{ path: 'ssd' }">
                            <img src="../assets/comp/ssd-logo.png" alt="SSD">
                        </router-link>
                    </div>
                    <div class="col-md-3">
                        <router-link :to="{ path: 'mr-light' }">
                            <img src="../assets/comp/mr-light-logo.png" alt="Mr Light">
                        </router-link>
                    </div>
                    <div class="col-md-3">
                        <router-link :to="{ path: 'kirac-elektrik' }">
                            <img src="../assets/comp/kirac-logo.png" alt="Kiraç Elektrik">
                        </router-link>
                    </div>
                </div>
                <div class="row space-t text-center">
                    <div class="col-md-3">
                        <router-link :to="{ path: 'tokyo' }">
                            <img src="../assets/comp/tokyo-logo.png" alt="Tokyo">
                        </router-link>
                    </div>
                    <div class="col-md-3">
                        <router-link :to="{ path: 'piterpan' }">
                            <img src="../assets/comp/piterpan-logo.png" alt="Piter Pan">
                        </router-link>
                    </div>
                    <div class="col-md-3">
                        <router-link :to="{ path: 'bambi' }">
                            <img src="../assets/comp/bambi-logo.png" alt="Bambi">
                        </router-link>
                    </div>
                    <div class="col-md-3">
                        <router-link :to="{ path: 'kidsclub' }">
                            <img src="../assets/comp/kidsclub-logo.png" alt="KidsClub">
                        </router-link>
                    </div>
                    <div class="col-md-3">
                        <router-link :to="{ path: 'mr-coffee' }">
                            <img src="../assets/comp/mr-coffe-logo.png" alt="Mr Coffee">
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

export default {
    name: "Kompanite",
    setup() { },
}

</script>

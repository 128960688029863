import { createRouter, createWebHistory } from "vue-router";
import Kryesore from "./views/Kryesore.vue";
import RrethNesh from "./views/RrethNesh.vue";
import Historiku from "./views/Historiku.vue";
import Kompanite from "./views/Kompanite.vue";
import Lajme from "./views/Lajme.vue";
import KidsclubOpening from "./views/news/KidsclubOpening.vue"
import ShareArchitects from "./views/news/ShareArchitects.vue"
import Ratech from "./views/comp/Ratech.vue";
import SSD from "./views/comp/Ssd.vue";
import Mrlight from "./views/comp/Mrlight.vue";
import MrCoffee from "./views/comp/Mrcoffee.vue";
import Tokyo from "./views/comp/Tokyo.vue";
import Piterpan from "./views/comp/Piterpan.vue";
import Bambi from "./views/comp/Bambi.vue";
import Kidsclub from "./views/comp/Kidsclub.vue";
import KiracElektrik from "./views/comp/KiracElektrik.vue";
import Kontakt from "./views/Kontakt.vue";
import NotFound from "./views/404.vue";

const routes = [
  {
    path: "/",
    name: "Kryesore",
    component: Kryesore,
    meta: {
      title: "R&M Group",
      metaTags: [
        {
          name: "description",
          content:
            "Fokusi ynë është teknologjia, sistemet e sigurisë, inxhinieria elektrike dhe elektronike dhe sistemet e ndriçimit. Kompanitë tona kanë zhvilluar me sukses projekte në të gjithë territorin e Shqipërisë, si edhe në disa vende të Evropës.",
        },
        {
          property: "og:description",
          content:
            "Fokusi ynë është teknologjia, sistemet e sigurisë, inxhinieria elektrike dhe elektronike dhe sistemet e ndriçimit. Kompanitë tona kanë zhvilluar me sukses projekte në të gjithë territorin e Shqipërisë, si edhe në disa vende të Evropës.",
        },
      ],
    },
  },
  {
    path: "/about-us",
    name: "Rreth Nesh",
    component: RrethNesh,
    meta: {
      title: "R&M Group - Rreth Nesh",
      metaTags: [
        {
          name: "description",
          content:
            "Fokusi ynë është teknologjia, sistemet e sigurisë, inxhinieria elektrike dhe elektronike dhe sistemet e ndriçimit. Kompanitë tona kanë zhvilluar me sukses projekte në të gjithë territorin e Shqipërisë, si edhe në disa vende të Evropës.",
        },
        {
          property: "og:description",
          content:
            "Fokusi ynë është teknologjia, sistemet e sigurisë, inxhinieria elektrike dhe elektronike dhe sistemet e ndriçimit. Kompanitë tona kanë zhvilluar me sukses projekte në të gjithë territorin e Shqipërisë, si edhe në disa vende të Evropës.",
        },
      ],
    },
  },
  {
    path: "/timeline",
    name: "Historiku",
    component: Historiku,
    meta: {
      title: "R&M Group - Historiku",
      metaTags: [
        {
          name: "description",
          content: "Historiku i kompanisë sonë.",
        },
        {
          property: "og:description",
          content: "Historiku i kompanisë sonë.",
        },
      ],
    },
  },
  {
    path: "/companies",
    name: "Kompanite",
    component: Kompanite,
    meta: {
      title: "R&M Group - Kompanite",
      metaTags: [
        {
          name: "description",
          content: "Kompanitë që bëjnë pjese te R&M Group.",
        },
        {
          property: "og:description",
          content: "Kompanitë që bëjnë pjese te R&M Group.",
        },
      ],
    },
  },
  {
    path: "/ratech",
    name: "Ratech",
    component: Ratech,
    meta: {
      title: "R&M Group - Ratech",
      metaTags: [
        {
          name: "description",
          content:
            "Ratech është një kompani e krijuar nga ekspertë të pasionuar të sigurisë. Me qasjen profesionale, të dedikuar ndaj dhënies së shërbimit, RATECH është pozicionuar si një ndër ofruesit kryesorë të shërbimeve të projektimit, instalimit e integrimit të sistemeve të sigurisë në Shqipëri.",
        },
        {
          property: "og:description",
          content:
            "Ratech është një kompani e krijuar nga ekspertë të pasionuar të sigurisë. Me qasjen profesionale, të dedikuar ndaj dhënies së shërbimit, RATECH është pozicionuar si një ndër ofruesit kryesorë të shërbimeve të projektimit, instalimit e integrimit të sistemeve të sigurisë në Shqipëri.",
        },
      ],
    },
  },
  {
    path: "/ssd",
    name: "SSD",
    component: SSD,
    meta: {
      title: "R&M Group - SSD",
      metaTags: [
        {
          name: "description",
          content:
            "SSD është distributori i parë rajonal i liderit të sistemeve të sigurisë “Dahua Technology”. Aktualisht.",
        },
        {
          property: "og:description",
          content:
            "SSD është distributori i parë rajonal i liderit të sistemeve të sigurisë “Dahua Technology”. Aktualisht",
        },
      ],
    },
  },
  {
    path: "/mr-light",
    name: "Mrlight",
    component: Mrlight,
    meta: {
      title: "R&M Group - Mr. Light",
      metaTags: [
        {
          name: "description",
          content:
            "Mr. Light është e specializuar në shitjen e ndriçuesve të cilësisë së lartë dhe projektimin e zbatimin e projekteve në fushën e ndriçimit. MR. LIGHT është distributor zyrtar i markave të njohura DR. LIGHT dhe LAMP 83.",
        },
        {
          property: "og:description",
          content:
            "Mr. Light është e specializuar në shitjen e ndriçuesve të cilësisë së lartë dhe projektimin e zbatimin e projekteve në fushën e ndriçimit. MR. LIGHT është distributor zyrtar i markave të njohura DR. LIGHT dhe LAMP 83.",
        },
      ],
    },
  },
  {
    path: "/mr-coffee",
    name: "MrCoffee",
    component: MrCoffee,
    meta: {
      title: "R&M Group - Mr. Coffee",
      metaTags: [
        {
          name: "description",
          content:
            "Mr. Coffee është zinxhiri më i ri i kafeneve në Tiranë. Kafeneja e parë u hap në vitin 2020, në një nga zonat rezidenciale më ekskluzive të Tiranës.",
        },
        {
          property: "og:description",
          content:
            "Mr. Coffee është zinxhiri më i ri i kafeneve në Tiranë. Kafeneja e parë u hap në vitin 2020, në një nga zonat rezidenciale më ekskluzive të Tiranës.",
        },
      ],
    },
  },
  {
    path: "/tokyo",
    name: "Tokyo",
    component: Tokyo,
    meta: {
      title: "R&M Group - Tokyo",
      metaTags: [
        {
          name: "description",
          content:
            "Tokyo është një kafene, snack bar dhe restorant me një ambjent elegant e të rehatshëm. Shërbimet e pijeve dhe ushqimit, kateringut, organizimit të eventeve ofrohen nga një staf profesional, me eksperiencë të gjerë, për të plotësuar kërkesat e klientëve individualë dhe atyre që vijnë nga fusha e biznesit.",
        },
        {
          property: "og:description",
          content:
            "Tokyo është një kafene, snack bar dhe restorant me një ambjent elegant e të rehatshëm. Shërbimet e pijeve dhe ushqimit, kateringut, organizimit të eventeve ofrohen nga një staf profesional, me eksperiencë të gjerë, për të plotësuar kërkesat e klientëve individualë dhe atyre që vijnë nga fusha e biznesit.",
        },
      ],
    },
  },
  {
    path: "/piterpan",
    name: "Piterpan",
    component: Piterpan,
    meta: {
      title: "R&M Group - Piter Pan",
      metaTags: [
        {
          name: "description",
          content:
            "PITER PAN administron një park lojërash në një nga zonat më të bukura të Tiranës. Stafi ynë i dedikuar e me përvojë është në shërbimin tuaj për kujdesin ndaj fëmijëve gjatë qëndrimit në park, por edhe gjatë organizimit të çdo lloj eventi për fëmijë. Kreativiteti dhe vëmendja e tyre ndaj detajeve do t’i bëjë eventet e fëmijëve tuaj të paharrueshme.",
        },
        {
          property: "og:description",
          content:
            "PITER PAN administron një park lojërash në një nga zonat më të bukura të Tiranës. Stafi ynë i dedikuar e me përvojë është në shërbimin tuaj për kujdesin ndaj fëmijëve gjatë qëndrimit në park, por edhe gjatë organizimit të çdo lloj eventi për fëmijë. Kreativiteti dhe vëmendja e tyre ndaj detajeve do t’i bëjë eventet e fëmijëve tuaj të paharrueshme.",
        },
      ],
    },
  },
  {
    path: "/bambi",
    name: "Bambi",
    component: Bambi,
    meta: {
      title: "R&M Group - Bambi",
      metaTags: [
        {
          name: "description",
          content:
            "BAMBI KIDS STORE është një kompani e re, që vepron në fushën e tregtimit të lodrave për fëmijë. Nëpërmjet shitjeve në dyqanet tona fizike, shitjeve online dhe shpërndarjes me shumicë, ofrojmë lodra të markave më të njohura. Personazhet e dashur të fëmijëve, lodrat edukative dhe materialet shkollore vijnë tek BAMBI KIDS nga marka më cilësore të prodhimit të lodrave, të cilat njihen për përdorimin e materialeve natyrale e të standardeve më të larta të sigurisë.",
        },
        {
          property: "og:description",
          content:
            "BAMBI KIDS STORE është një kompani e re, që vepron në fushën e tregtimit të lodrave për fëmijë. Nëpërmjet shitjeve në dyqanet tona fizike, shitjeve online dhe shpërndarjes me shumicë, ofrojmë lodra të markave më të njohura. Personazhet e dashur të fëmijëve, lodrat edukative dhe materialet shkollore vijnë tek BAMBI KIDS nga marka më cilësore të prodhimit të lodrave, të cilat njihen për përdorimin e materialeve natyrale e të standardeve më të larta të sigurisë.",
        },
      ],
    },
  },
  {
    path: "/kidsclub",
    name: "Kidsclub",
    component: Kidsclub,
    meta: {
      title: "R&M Group - Kids Club",
      metaTags: [
        {
          name: "description",
          content:
            "KIDS CLUB është një hapësirë për fëmijët, në të cilën ata mund të zhvillojnë aftësitë e tyre në subjekte të ndryshme shkencore dhe artistike, të kryejnë aktivitete të tjera passhkollore apo thjesht të argëtohen duke luajtur. KIDS CLUB është projektuar duke mbajtur parasysh imagjinatën dhe dëshirat fëmijërore, si një vend miqësor që do t’u mundësojë fëmijëve të shprehin lirisht kreativitetin dhe aftësitë e tyre.",
        },
        {
          property: "og:description",
          content:
            "KIDS CLUB është një hapësirë për fëmijët, në të cilën ata mund të zhvillojnë aftësitë e tyre në subjekte të ndryshme shkencore dhe artistike, të kryejnë aktivitete të tjera passhkollore apo thjesht të argëtohen duke luajtur. KIDS CLUB është projektuar duke mbajtur parasysh imagjinatën dhe dëshirat fëmijërore, si një vend miqësor që do t’u mundësojë fëmijëve të shprehin lirisht kreativitetin dhe aftësitë e tyre.",
        },
      ],
    },
  },
  {
    path: "/kirac-elektrik",
    name: "KiracElektrik",
    component: KiracElektrik,
    meta: {
      title: "R&M Group - Kirac Elektrik",
      metaTags: [
        {
          name: "description",
          content:
            "KIDS CLUB është një hapësirë për fëmijët, në të cilën ata mund të zhvillojnë aftësitë e tyre në subjekte të ndryshme shkencore dhe artistike, të kryejnë aktivitete të tjera passhkollore apo thjesht të argëtohen duke luajtur. KIDS CLUB është projektuar duke mbajtur parasysh imagjinatën dhe dëshirat fëmijërore, si një vend miqësor që do t’u mundësojë fëmijëve të shprehin lirisht kreativitetin dhe aftësitë e tyre.",
        },
        {
          property: "og:description",
          content:
            "KIDS CLUB është një hapësirë për fëmijët, në të cilën ata mund të zhvillojnë aftësitë e tyre në subjekte të ndryshme shkencore dhe artistike, të kryejnë aktivitete të tjera passhkollore apo thjesht të argëtohen duke luajtur. KIDS CLUB është projektuar duke mbajtur parasysh imagjinatën dhe dëshirat fëmijërore, si një vend miqësor që do t’u mundësojë fëmijëve të shprehin lirisht kreativitetin dhe aftësitë e tyre.",
        },
      ],
    },
  },
  {
    path: "/news",
    name: "Lajme",
    component: Lajme,
    meta: {
      title: "R&M Group - Lajme",
      metaTags: [
        {
          name: "description",
          content: "Kompanitë që bëjnë pjese te R&M Group.",
        },
        {
          property: "og:description",
          content: "Kompanitë që bëjnë pjese te R&M Group.",
        },
      ],
    },
  },
  {
    path: "/kidsclub-hap-dyert-dhe-fillon-veprimtarine",
    name: "KidsclubOpening",
    component: KidsclubOpening,
    meta: {
      title: "R&M Group - KIDS CLUB",
      metaTags: [
        {
          name: "description",
          content: "KIDS CLUB hap dyert dhe fillon veprimtarinë.",
        },
        {
          property: "og:description",
          content: "KIDS CLUB hap dyert dhe fillon veprimtarinë.",
        },
      ],
    },
  },
  {
    path: "/r&m-group-prezanton-ne-share-architects",
    name: "ShareArchitects",
    component: ShareArchitects,
    meta: {
      title: "R&M Group - Share Architects",
      metaTags: [
        {
          name: "description",
          content: "R&M GROUP prezanton në SHARE ARCHITECTS.",
        },
        {
          property: "og:description",
          content: "R&M GROUP prezanton në SHARE ARCHITECTS.",
        },
      ],
    },
  },
  {
    path: "/contact",
    name: "Kontakt",
    component: Kontakt,
    meta: {
      title: "R&M Group - Kontakt",
      metaTags: [
        {
          name: "description",
          content:
            "Fokusi ynë është teknologjia, sistemet e sigurisë, inxhinieria elektrike dhe elektronike dhe sistemet e ndriçimit. Kompanitë tona kanë zhvilluar me sukses projekte në të gjithë territorin e Shqipërisë, si edhe në disa vende të Evropës.",
        },
        {
          property: "og:description",
          content:
            "Fokusi ynë është teknologjia, sistemet e sigurisë, inxhinieria elektrike dhe elektronike dhe sistemet e ndriçimit. Kompanitë tona kanë zhvilluar me sukses projekte në të gjithë territorin e Shqipërisë, si edhe në disa vende të Evropës.",
        },
      ],
    },
  },
  // 404 not found
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFound,
    meta: {
      title: "R&M Group - 404",
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "is-active",
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(
    document.querySelectorAll("[data-vue-router-controlled]")
  ).map((el) => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

export default router;

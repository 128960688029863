<template>
  <section class="wrapper timeline">
    <div class="container">
      <div class="line">
        <div class="row space-t d-md-none">
          <div class="col-md-12">
            <h1 class="text-center">{{ $t('menu.history') }}</h1>
          </div>
        </div>
        <div class="row space-t">
          <div class="col-md-12">

            <div class="swiper-holder">
              <div class="swiper-container">
                <div class="swiper-wrapper">
                  <div class="swiper-slide"
                    :style="{'background-image': 'url(' + require('../assets/timeline/2007.png') + ')'}"
                    :data-year="$t('timeline.2007.year')">
                    <div class="swiper-slide-content"><span class="timeline-year">{{ $t('timeline.2007.year') }}</span>
                      <h4 class="timeline-title">{{ $t('timeline.2007.title') }}</h4>
                    </div>
                  </div>
                  <div class="swiper-slide"
                    :style="{'background-image': 'url(' + require('../assets/timeline/2011.png') + ')'}"
                    :data-year="$t('timeline.2011.year')">
                    <div class="swiper-slide-content"><span class="timeline-year">{{ $t('timeline.2011.year') }}</span>
                      <h4 class="timeline-title">{{ $t('timeline.2011.title') }}</h4>
                    </div>
                  </div>
                  <div class="swiper-slide"
                    :style="{'background-image': 'url(' + require('../assets/timeline/2014.png') + ')'}"
                    :data-year="$t('timeline.2014.year')">
                    <div class="swiper-slide-content"><span class="timeline-year">{{ $t('timeline.2014.year') }}</span>
                      <h4 class="timeline-title">{{ $t('timeline.2014.title') }}</h4>
                    </div>
                  </div>
                  <div class="swiper-slide"
                    :style="{'background-image': 'url(' + require('../assets/timeline/2017.png') + ')'}"
                    :data-year="$t('timeline.2017.year')">
                    <div class="swiper-slide-content"><span class="timeline-year">{{ $t('timeline.2017.year') }}</span>
                      <h4 class="timeline-title">{{ $t('timeline.2017.title') }}</h4>
                    </div>
                  </div>
                  <div class="swiper-slide"
                    :style="{'background-image': 'url(' + require('../assets/timeline/2018.png') + ')'}"
                    :data-year="$t('timeline.2018.year')">
                    <div class="swiper-slide-content"><span class="timeline-year"> {{ $t('timeline.2018.year') }}</span>
                      <h4 class="timeline-title">{{ $t('timeline.2018.title') }}</h4>
                    </div>
                  </div>
                  <div class="swiper-slide"
                    :style="{'background-image': 'url(' + require('../assets/timeline/shkurt-2019.png') + ')'}"
                    :data-year="$t('timeline.feb2019.year')">
                    <div class="swiper-slide-content"><span class="timeline-year">{{ $t('timeline.feb2019.year')
                        }}</span>
                      <h4 class="timeline-title">{{ $t('timeline.feb2019.title') }}</h4>
                    </div>
                  </div>
                  <div class="swiper-slide"
                    :style="{'background-image': 'url(' + require('../assets/timeline/maj-2019.png') + ')'}"
                    :data-year="$t('timeline.may2019.year')">
                    <div class="swiper-slide-content"><span class="timeline-year">{{ $t('timeline.may2019.year')
                        }}</span>
                      <h4 class="timeline-title">{{ $t('timeline.may2019.title') }}</h4>
                    </div>
                  </div>
                  <div class="swiper-slide"
                    :style="{'background-image': 'url(' + require('../assets/timeline/qer-2019.png') + ')'}"
                    :data-year="$t('timeline.june2019.year')">
                    <div class="swiper-slide-content"><span class="timeline-year">{{ $t('timeline.june2019.year')
                        }}</span>
                      <h4 class="timeline-title">{{ $t('timeline.june2019.title') }}</h4>
                    </div>
                  </div>
                  <div class="swiper-slide"
                    :style="{'background-image': 'url(' + require('../assets/timeline/dhjetor-2019.png') + ')'}"
                    :data-year="$t('timeline.dec2019.year')">
                    <div class="swiper-slide-content"><span class="timeline-year">{{ $t('timeline.dec2019.year')
                        }}</span>
                      <h4 class="timeline-title">{{ $t('timeline.dec2019.title') }}</h4>
                    </div>
                  </div>
                  <div class="swiper-slide"
                    :style="{'background-image': 'url(' + require('../assets/timeline/maj-2020.png') + ')'}"
                    :data-year="$t('timeline.2020.year')">
                    <div class="swiper-slide-content"><span class="timeline-year">{{ $t('timeline.2020.year') }}</span>
                      <h4 class="timeline-title">{{ $t('timeline.2020.title') }}</h4>
                    </div>
                  </div>
                  <div class="swiper-slide"
                    :style="{'background-image': 'url(' + require('../assets/timeline/sep2020.png') + ')'}"
                    :data-year="$t('timeline.sep2020.year')">
                    <div class="swiper-slide-content"><span class="timeline-year">{{ $t('timeline.sep2020.year')
                        }}</span>
                      <h4 class="timeline-title">{{ $t('timeline.sep2020.title') }}</h4>
                    </div>
                  </div>
                  <div class="swiper-slide"
                    :style="{'background-image': 'url(' + require('../assets/timeline/jan2021.png') + ')'}"
                    :data-year="$t('timeline.jan2021.year')">
                    <div class="swiper-slide-content"><span class="timeline-year">{{ $t('timeline.jan2021.year')
                        }}</span>
                      <h4 class="timeline-title">{{ $t('timeline.jan2021.title') }}</h4>
                    </div>
                  </div>
                  <div class="swiper-slide"
                    :style="{'background-image': 'url(' + require('../assets/timeline/may2021.png') + ')'}"
                    :data-year="$t('timeline.may2021.year')">
                    <div class="swiper-slide-content"><span class="timeline-year">{{ $t('timeline.may2021.year')
                        }}</span>
                      <h4 class="timeline-title">{{ $t('timeline.may2021.title') }}</h4>
                    </div>
                  </div>
                  <div class="swiper-slide"
                    :style="{'background-image': 'url(' + require('../assets/timeline/may2021-2.png') + ')'}"
                    :data-year="$t('timeline.may2021-2.year')">
                    <div class="swiper-slide-content"><span class="timeline-year">{{ $t('timeline.may2021-2.year')
                        }}</span>
                      <h4 class="timeline-title">{{ $t('timeline.may2021-2.title') }}</h4>
                    </div>
                  </div>
                  <div class="swiper-slide"
                    :style="{'background-image': 'url(' + require('../assets/timeline/aug2021.png') + ')'}"
                    :data-year="$t('timeline.aug2021.year')">
                    <div class="swiper-slide-content"><span class="timeline-year">{{ $t('timeline.aug2021.year')
                        }}</span>
                      <h4 class="timeline-title">{{ $t('timeline.aug2021.title') }}</h4>
                    </div>
                  </div>
                  <div class="swiper-slide"
                    :style="{'background-image': 'url(' + require('../assets/timeline/oct2021.png') + ')'}"
                    :data-year="$t('timeline.oct2021.year')">
                    <div class="swiper-slide-content"><span class="timeline-year">{{ $t('timeline.oct2021.year')
                        }}</span>
                      <h4 class="timeline-title">{{ $t('timeline.oct2021.title') }}</h4>
                    </div>
                  </div>
                  <div class="swiper-slide"
                    :style="{'background-image': 'url(' + require('../assets/timeline/nov2021.png') + ')'}"
                    :data-year="$t('timeline.nov2021.year')">
                    <div class="swiper-slide-content"><span class="timeline-year">{{ $t('timeline.nov2021.year')
                        }}</span>
                      <h4 class="timeline-title">{{ $t('timeline.nov2021.title') }}</h4>
                    </div>
                  </div>
                  <div class="swiper-slide"
                    :style="{'background-image': 'url(' + require('../assets/timeline/apr2022.png') + ')'}"
                    :data-year="$t('timeline.apr2022.year')">
                    <div class="swiper-slide-content"><span class="timeline-year">{{ $t('timeline.apr2022.year')
                        }}</span>
                      <h4 class="timeline-title">{{ $t('timeline.apr2022.title') }}</h4>
                    </div>
                  </div>
                  <div class="swiper-slide"
                    :style="{'background-image': 'url(' + require('../assets/timeline/may2022.png') + ')'}"
                    :data-year="$t('timeline.may2022.year')">
                    <div class="swiper-slide-content"><span class="timeline-year">{{ $t('timeline.may2022.year')
                        }}</span>
                      <h4 class="timeline-title">{{ $t('timeline.may2022.title') }}</h4>
                    </div>
                  </div>
                  <div class="swiper-slide"
                    :style="{'background-image': 'url(' + require('../assets/timeline/may2022-2.png') + ')'}"
                    :data-year="$t('timeline.may2022-2.year')">
                    <div class="swiper-slide-content"><span class="timeline-year">{{ $t('timeline.may2022-2.year')
                        }}</span>
                      <h4 class="timeline-title">{{ $t('timeline.may2022-2.title') }}</h4>
                    </div>
                  </div>
                  <div class="swiper-slide"
                    :style="{'background-image': 'url(' + require('../assets/timeline/july2022.png') + ')'}"
                    :data-year="$t('timeline.july2022.year')">
                    <div class="swiper-slide-content"><span class="timeline-year">{{ $t('timeline.july2022.year')
                        }}</span>
                      <h4 class="timeline-title">{{ $t('timeline.july2022.title') }}</h4>
                    </div>
                  </div>
                  <div class="swiper-slide"
                    :style="{'background-image': 'url(' + require('../assets/timeline/sep2022.png') + ')'}"
                    :data-year="$t('timeline.sep2022.year')">
                    <div class="swiper-slide-content"><span class="timeline-year">{{ $t('timeline.sep2022.year')
                        }}</span>
                      <h4 class="timeline-title">{{ $t('timeline.sep2022.title') }}</h4>
                    </div>
                  </div>
                </div>
                <div class="swiper-button-prev"></div>
                <div class="swiper-button-next"></div>
                <div class="swiper-pagination"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {

  name: "Historiku",

  mounted() {
    if (localStorage.getItem('reloaded')) {
      localStorage.removeItem('reloaded');
    } else {
      localStorage.setItem('reloaded', '1');
      location.reload();
    }
  }

};

</script>

<style scoped>
.swiper-button-next:after,
.swiper-button-prev:after {
  display: none;
}
</style>
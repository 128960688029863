<template>
  <div>

    <Header />
    <Hero />

    <transition mode="out-in" enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut">
      <router-view />
    </transition>


    <Sitemap />
    <Footer />
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Hero from './components/Hero'
import Sitemap from './components/Sitemap'
import Footer from './components/Footer'


export default {
  name: 'App',
  components: {
    Header,
    Hero,
    Sitemap,
    Footer
  },
}

</script>

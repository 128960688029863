import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import App from "./App.vue";
import router from "./router";
import VueGoogleMaps from "@fawmi/vue-google-maps";

import "animate.css";

import { languages } from "./i18n/lang.js";
import { defaultLocale } from "./i18n/lang.js";

import "flag-icon-css/css/flag-icon.css";
import "@fortawesome/fontawesome-free/js/fontawesome";
import "@fortawesome/fontawesome-free/js/solid";
import "@fortawesome/fontawesome-free/js/regular";
import "@fortawesome/fontawesome-free/js/brands";

const messages = Object.assign(languages);

const i18n = createI18n({
  locale: defaultLocale,
  fallbackLocale: "al",
  messages,
});

createApp(App)
  .use(router)
  .use(VueGoogleMaps, {
    load: {
      key: "AIzaSyCTVzBOceFUIgjPlpMas3Cv586fPan8cyY",
    },
  })
  .use(i18n)
  .mount("#app");

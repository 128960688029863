<template>
  <footer class="animate__animated animate__fadeIn">
    <div class="container">
      <div class="row">
        <div>
          <div class="col-md-6">
            <p class="copyright float-end">
              Copyright © {{ year() }} <b>R&M Group</b>, All Rights Reserved
            </p>
          </div>
          <div class="col-md-6">
            <ul class="links float-start">
              <li>
                <a @click="politikat">{{ $t("footer.policy") }}</a>
              </li>
              <li>
                <a @click="kushtet">{{ $t("footer.use") }}</a>
              </li>
            </ul>
          </div>
          <div class="author">
            <a
              href="http://albidudija.com"
              target="_blank"
              data-toggle="tooltip"
              title="Crafted by Albi"
            >
              <img src="../assets/a-logo.svg" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  methods: {
    politikat: function () {
      var pdf = "/faq/Politika-e-privatesise.pdf";
      window.open(pdf);
    },

    kushtet: function () {
      var pdf = "/faq/Kushtet-e-perdorimit-te-faqes.pdf";
      window.open(pdf);
    },

    year: function () {
      var year = new Date().getFullYear();
      return year;
    },
  },
  mounted() {
    this.year();
  },
};
</script>

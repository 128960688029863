<template>
    <section class="wrapper companies ratech">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="logo text-center">
                        <a href="#"><img src="../../assets/comp/ratech-logo.png" alt="Ratech"/></a>
                    </div>
                </div>
            </div>
            <div class="row animate__animated animate__fadeIn">
                <div class="col-md-8 offset-md-2 col-sm-12 mt-4 mb-4">
                    <p v-html="$t('companies.ratech.desc')"></p>
                </div>
                <div class="col-md-4 col-sm-12 offset-md-2 mb-4">
                    <div v-for="sol in solutions1" :key="sol" class="d-flex mb-1">
                        <div class="icon icon-shape bg-primary rounded-circle ml-4 mr-4">
                            <i class="fas fa-check"></i>
                        </div>
                        <span>{{ sol.title }}</span>
                    </div>
                </div>
                <div class="col-md-4 col-sm-12 mb-4">
                    <div v-for="sol in solutions2" :key="sol" class="d-flex mb-1">
                        <div class="icon icon-shape bg-primary rounded-circle ml-4 mr-4">
                            <i class="fas fa-check"></i>
                        </div>
                        <span>{{ sol.title }}</span>
                    </div>
                </div>
            </div>
            <div class="row work-process mt-4 animate__animated animate__fadeIn">
                <div class="col-md-2 offset-md-2">
                    <div class="tw-work-process">
                        <div style="background-color:#4235436;" class="process-wrapper d-table">
                            <div class="process-inner d-table-cell"> 
                                <i class="fas fa-drafting-compass"></i>
                            </div>
                        </div>
                        <p v-html="$t('companies.ratech.steps.step1')"></p>
                    </div>
                    <img class="arrow-shape" src="../../assets/arrow.png">
                </div>
                <div class="col-md-2">
                    <div class="tw-work-process ">
                        <div style="background-color:#fc5454;" class="process-wrapper d-table">
                            <div class="process-inner d-table-cell"> 
                                <i class="fas fa-project-diagram"></i>
                            </div>
                        </div>
                        <p v-html="$t('companies.ratech.steps.step2')"></p>
                    </div>
                    <img class="arrow-shape" src="../../assets/arrow.png">
                </div>
                <div class="col-md-2">
                    <div class="tw-work-process">
                        <div style="background-color:#5c54ff;" class="process-wrapper d-table">
                            <div class="process-inner d-table-cell"> 
                                <i class="fas fa-tools"></i>
                            </div>
                        </div>
                        <p v-html="$t('companies.ratech.steps.step3')"></p>
                    </div>
                    <img class="arrow-shape" src="../../assets/arrow.png">
                </div>
                <div class="col-md-2">
                    <div class="tw-work-process">
                        <div style="background-color:#ffb72f;" class="process-wrapper d-table">
                            <div class="process-inner d-table-cell"> 
                                <i class="fas fa-hands-helping"></i>
                            </div>
                        </div>
                        <p v-html="$t('companies.ratech.steps.step4')"></p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "Ratech",
    data(){
        return{
            solutions1: [
                {title: this.$t('companies.ratech.solutions.solu1')},
                {title: this.$t('companies.ratech.solutions.solu2')},
                {title: this.$t('companies.ratech.solutions.solu3')},
                {title: this.$t('companies.ratech.solutions.solu4')},
                {title: this.$t('companies.ratech.solutions.solu5')},
                {title: this.$t('companies.ratech.solutions.solu6')}
            ],
            solutions2: [
                {title: this.$t('companies.ratech.solutions.solu7')},
                {title: this.$t('companies.ratech.solutions.solu8')},
                {title: this.$t('companies.ratech.solutions.solu9')},
                {title: this.$t('companies.ratech.solutions.solu10')},
                {title: this.$t('companies.ratech.solutions.solu11')},
                {title: this.$t('companies.ratech.solutions.solu12')},
                {title: this.$t('companies.ratech.solutions.solu13')}
            ],
            
        }
    },
}
</script>

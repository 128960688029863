<template>
        <section class="wrapper companies mr-coffee">
        <div class="container animate__animated animate__fadeIn">
            <div class="row">
                <div class="col-md-12">
                    <div class="logo text-center">
                        <a href="#"><img src="../../assets/comp/bambi-logo.png" alt="Bambi"/></a>
                        <p class="slogan mt-4">{{ $t('companies.bambi.slogan') }}</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-8 offset-md-2 col-sm-12 mt-4 mb-4">
                    <p v-html="$t('companies.bambi.desc')"></p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "Bambi",
    setup() {
        
    },
}
</script>
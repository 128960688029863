<template>
    <section class="wrapper news">
        <div class="container">
            <div class="line">
                <div class="row space-t">
                    <div class="col-md-12">
                        <h1 class="text-center">{{ $t('news.news1.title') }}</h1>
                    </div>
                </div>
                <div class="row space-t">
                    <div class="col-md-4">
                        <img src="../../assets/building-kidsclub.png" class="card-img-top" alt="Kidsclub">
                    </div>
                    <div class="col-md-8">
                        <p v-html="$t('news.news1.shortdesc')"></p>
                        <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/NSq5at4bTSE"
                            title="YouTube video player" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "KidsclubOpening",
    setup() {

    },
}
</script>
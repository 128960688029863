<template>
  <header class="animate__animated animate__fadeIn">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="left rm-logo">
            <router-link to='/'>
              <img src="../assets/rm-logo.png" alt="R&M Group Logo" />
            </router-link>
          </div>
        </div>
        <div class="col-md-6">
          <div class="d-flex right align-items-end">
            <div class="language">
              <span class="flag-icon" :class="$t('lang')"></span> 

              <select v-model="$i18n.locale" @change="handeChange($event)">
                <option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale">{{ locale }}</option>
              </select>

            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>

export default {
  name: "Nav",

  mounted(){
    this.$i18n.locale = localStorage.getItem("lang") || 'Albanian';
  },

  methods: {
    handeChange(event){
      localStorage.setItem('lang', event.target.value);
      window.location.reload();
    }
  }
};

</script>


<template>

  <section class="hero animate__animated animate__fadeIn">

    <svg id="top" data-name="Top" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2003.59 174">
      <path id="curve1" d="M0 761s902 198 2003.59 0l.41 122H-5Z" transform="translate(-1.5 -672.5)" fill="#333333" />
    </svg>

    <svg id="bottom" data-name="Bottom" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2003.59 174">
      <path id="curve2" d="M0 761s902 198 2003.59 0l.41 122H-5Z" transform="translate(-1.5 -672.5)" fill="#333333" />
    </svg>


    <div class="menu d-flex">
      <router-link :to="{ path: 'about-us' }">
        <h3 id="rreth">{{ $t('menu.aboutUs') }}</h3>
      </router-link>
      <router-link :to="{ path: 'timeline' }">
        <h3 id="historiku">{{ $t('menu.history') }}</h3>
      </router-link>
      <router-link :to="{ path: 'companies' }">
        <h3 id="komp">{{ $t('menu.companies') }}</h3>
      </router-link>
      <router-link :to="{ path: 'news' }">
        <h3 id="lajme">{{ $t('menu.news') }}</h3>
      </router-link>
      <router-link :to="{ path: 'contact' }" class="no-b">
        <h3 id="kontakt">{{ $t('menu.contact') }}</h3>
      </router-link>
    </div>

    <div class="list-container" v-bind:class="{ active: isActive }">
      <button @click="handleToggle" class="more-button" aria-label="Menu Button">
        <div class="menu-icon-wrapper">
          <div class="menu-icon-line half first"></div>
          <div class="menu-icon-line"></div>
          <div class="menu-icon-line half last"></div>
        </div>
      </button>
      <ul class="more-button-list">
        <li class="more-button-list-item">
          <router-link :to="{ path: 'about-us' }"><span>Rreth nesh</span></router-link>
        </li>
        <li class="more-button-list-item">
          <router-link :to="{ path: 'timeline' }"><span>Historiku</span></router-link>
        </li>
        <li class="more-button-list-item">
          <router-link :to="{ path: 'companies' }"><span>Kompanitë</span></router-link>
        </li>
        <li class="more-button-list-item">
          <router-link :to="{ path: 'news' }"><span>Lajme</span></router-link>
        </li>
        <li class="more-button-list-item">
          <router-link :to="{ path: 'contact' }"><span>Kontakt</span></router-link>
        </li>
      </ul>
    </div>

  </section>
</template>

<script>

export default {

  name: "Hero",

  data() {
    return {
      isActive: false
    };
  },

  methods: {
    handleToggle() {
      this.isActive = !this.isActive;
    }
  }

};

</script>

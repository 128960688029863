<template>
    <section class="wrapper news">
        <div class="container">
            <div class="line">
                <div class="row space-t">
                    <div class="col-md-12">
                        <h1 class="text-center">{{ $t('menu.news') }}</h1>
                    </div>
                </div>
                <div class="row space-t">
                    <div class="col-md-4">
                        <div class="card">
                            <img src="../assets/share-architects.jpg" class="card-img-top" alt="Share Architects">
                            <div class="card-body">
                                <h5 class="card-title">{{ $t('news.cards.news2.title') }}</h5>
                                <p class="card-text">{{ $t('news.cards.news2.shortdesc') }}</p>
                                <router-link :to="{ path: 'r&m-group-prezanton-ne-share-architects' }" class="btn">
                                    {{ $t('news.buttons.showmore') }}
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card">
                            <img src="../assets/building-kidsclub.png" class="card-img-top" alt="Kidsclub">
                            <div class="card-body">
                                <h5 class="card-title">{{ $t('news.cards.news1.title') }}</h5>
                                <p class="card-text">{{ $t('news.cards.news1.shortdesc') }}</p>
                                <router-link :to="{ path: 'kidsclub-hap-dyert-dhe-fillon-veprimtarine' }" class="btn">
                                    {{ $t('news.buttons.showmore') }}
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</section>
</template>

<script>


export default {
    name: "Lajme",
    setup() {

    },
}
</script>